export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [9,[2]],
		"/(app)/channel/[id]": [10,[2]],
		"/(app)/channel/[id]/[settingGroup]": [11,[2]],
		"/(app)/config/[kind]/[type]/[id]": [12,[2]],
		"/(app)/config/[kind]/[type]/[id]/[config]": [13,[2]],
		"/(app)/connectors": [14,[2]],
		"/(app)/customers/[[search]]": [22,[2]],
		"/(app)/customer/[id]": [15,[2,3]],
		"/(app)/customer/[id]/attributes": [16,[2,3]],
		"/(app)/customer/[id]/data-sync": [17,[2,3]],
		"/(app)/customer/[id]/details": [18,[2,3]],
		"/(app)/customer/[id]/dynamic-pricing": [19,[2,3]],
		"/(app)/customer/[id]/orders": [20,[2,3]],
		"/(app)/customer/[id]/segments": [21,[2,3]],
		"/(app)/dashboard": [23,[2]],
		"/error": [54],
		"/(auth)/forgot": [52,[8]],
		"/(auth)/login": [53,[8]],
		"/(app)/logs": [24,[2]],
		"/(app)/orders/[[search]]": [30,[2]],
		"/(app)/order/[id]": [25,[2,4]],
		"/(app)/order/[id]/details": [26,[2,4]],
		"/(app)/order/[id]/fulfillments": [27,[2,4]],
		"/(app)/order/[id]/history": [28,[2,4]],
		"/(app)/order/[id]/refunds": [29,[2,4]],
		"/(app)/products/[[search]]": [37,[2]],
		"/(app)/product/[id]": [31,[2,5]],
		"/(app)/product/[id]/attributes": [32,[2,5]],
		"/(app)/product/[id]/channels": [33,[2,5]],
		"/(app)/product/[id]/data-sync": [34,[2,5]],
		"/(app)/product/[id]/details": [35,[2,5]],
		"/(app)/product/[id]/dynamic-pricing": [36,[2,5]],
		"/(app)/queue": [38,[2]],
		"/(app)/queue/error": [39,[2]],
		"/(app)/search/errors": [40,[2]],
		"/(app)/search/products/field/[field]": [41,[2]],
		"/(app)/settings": [42,[2,6]],
		"/(app)/settings/notifications": [43,[2,6]],
		"/(app)/settings/product-templates": [44,[2,6]],
		"/(app)/settings/users": [45,[2,6]],
		"/(app)/settings/users/[id]": [46,[2,6,7]],
		"/(app)/settings/users/[id]/attributes": [47,[2,6,7]],
		"/(app)/settings/users/[id]/details": [48,[2,6,7]],
		"/(app)/settings/users/[id]/segments": [49,[2,6,7]],
		"/(app)/source/[id]": [50,[2]],
		"/(app)/source/[id]/[settingGroup]": [51,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';